<template>
  <div
    class="sidebar"
    :class="{ 'sidebar--open': isSidebarOpen }"
    ref="menuSidebar"
  >
    <div>
      <ul v-for="(topic, idx) in faqData" :key="idx + topic.title">
        <li class="sidebar__link" @click="toggleTopic(topic.id)">
          {{ topic["title_" + locale] }}
          <span
            :class="{
              'icon-keyboard_arrow_down sidebar__icon': topic.opened,
              'icon-keyboard_arrow_right  sidebar__icon': !topic.opened,
            }"
          ></span>
        </li>
        <div v-if="topic.opened">
          <li
            v-for="(content, id) in topic.content"
            :key="id + content.title"
            class="sidebar__menu-item"
            @click="closeSidebar"
          >
            <router-link
              :to="`/${locale}/${topic.slug}/${content.slug}`"
              class="sidebar__link sidebar__link--special"
            >
              {{ content["title_" + locale] }}
            </router-link>
          </li>
        </div>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "FaqSidebar",
  props: {
    isSidebarOpen: {
      type: Boolean,
      default: false,
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      faqData: "app/getFaqData",
      locale: "app/getLocale",
    }),
  },
  methods: {
    toggleTopic(id) {
      this.$store.commit("app/TOGGLE_FAQ_ITEM", { id });
    },
    closeTopic(id) {
      this.$store.commit("app/TOGGLE_FAQ_ITEM", { id, value: false });
    },
    closeSidebar() {
      this.$emit("close-sidebar");
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/variables.scss";

.sidebar {
  background-color: $main-text;
  position: fixed;
  top: 60px;
  left: 0;
  overflow-x: hidden;
  overflow-y: scroll;
  color: $main-text;
  width: 344px;
  bottom: 0;
  border-right: 1px solid $special-text;
  &::-webkit-scrollbar {
    width: 2px;
  }
  &::-webkit-scrollbar-track {
    border-radius: 5px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: $special-text;
    border-radius: 6px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background-color: darken($main-text, 10%);
  }
  @media (max-width: 1000px) {
    position: fixed;
    left: -350px;
    transition: left 0.3s ease;
    @media (max-width: 425px) {
      left: -100%;
      width: 100%;
      border: none;
    }
    &--open {
      left: 0;
    }
  }
  &__link {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    margin: 0 5px;
    padding: 10px 25px;
    border-radius: 10px;
    text-decoration: none;
    transition: color 0.3s ease;
    color: #1c1e21;
    font-weight: 500;
    gap: 10px;
    font-size: 16px;
    font-weight: bold;
    @media (max-width: 1800px) {
      padding: 10px 25px;
      font-size: 14px;
    }
    @media (max-width: 1024px) {
      font-size: 0.875rem;
    }
    @media (max-width: 1400px) {
      padding: 15px 20px;
      gap: 10px;
    }
    &:hover {
      color: $light-green;
      background-color: $special-text;
    }
    &--special {
      padding-left: 50px;
      font-size: 14px;
      font-weight: 400;
      @media (max-width: 1024px) {
        font-size: 12px;
      }
    }
  }
  & li.active a {
    color: $light-green;
  }
  &__icon {
    font-size: 26px;
  }
}
.sidebar__menu-item {
  list-style-type: none;
}
.router-link-active {
  color: $light-green !important;
  font-weight: bold;
}
</style>
