<template>
  <div id="app">
    <faq-sidebar :isSidebarOpen="isSidebarOpen" @close-sidebar="closeSidebar" />
    <faq-header
      :isSidebarOpen="isSidebarOpen"
      @toggle-sidebar="toggleSideBar"
      @close-sidebar="closeSidebar" />
    <div class="wrapper blur" @click="closeSidebar">
      <router-view />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import FaqHeader from "@/components/FaqHeader.vue";
import FaqSidebar from "@/components/FaqSidebar.vue";
import ClickOutside from "vue-click-outside";

export default {
  name: "App",
  components: { FaqHeader, FaqSidebar },
  computed: {
    ...mapGetters({
      locale: "app/getLocale",
    }),
  },
  directives: {
    ClickOutside,
  },
  data() {
    return {
      loading: false,
      isSidebarOpen: false,
    };
  },
  watch: {
    locale(newLocale) {
      if (newLocale === "fa") {
        document.documentElement.setAttribute("dir", "rtl");
      } else {
        document.documentElement.removeAttribute("dir");
      }
    },
  },
  created() {
    this.getAppData();
  },
  mounted() {
    if (this.locale === "fa") {
      document.documentElement.setAttribute("dir", "rtl");
    }
  },

  methods: {
    getAppData() {
      this.loading = true;
      this.$store
        .dispatch("app/getFaqData")
        .finally(() => (this.loading = false));
    },
    toggleSideBar(event) {
      event.stopPropagation();
      this.isSidebarOpen = !this.isSidebarOpen;
    },
    closeSidebar() {
      this.isSidebarOpen = false;
    },
  },
};
</script>
<style lang="scss">
@import "@/assets/styles/styles.scss";
// .header__blur {
//   opacity: 0.1;
//   background-color: black;
// }
</style>
