<template>
  <article>
    <div class="general__header">
      <router-link class="general__link" :to="contentData.homeLink">
        <span class="icon-home"></span>
        <span class="icon-keyboard_arrow_right"></span>
      </router-link>
      <router-link class="general__link" :to="contentData.chapterLink">
        {{ contentData.chapterTitle }}
        <span class="icon-keyboard_arrow_right"></span>
      </router-link>
      <router-link
        class="general__link general__link--special"
        :to="contentData.currentLink">
        {{ contentData.currentQ }}
      </router-link>
    </div>
    <div class="general__content">
      <h1 class="general__title">{{ contentData.currentQ }}</h1>
      <div
        v-html="contentData.htmlContent"
        ref="contentRef"
        class="html-wrapper"></div>
    </div>
    <div class="general__footer">
      <router-link
        class="general__btn general__btn--prev"
        :to="contentData.prevRoute">
        <span class="icon-circle-left"></span>
        {{ contentData.prevLabel }}
      </router-link>
      <router-link
        class="general__btn general__btn--next"
        :to="contentData.nextRoute">
        {{ contentData.nextLabel }}
        <span class="icon-circle-right"></span>
      </router-link>
    </div>
    <popup-item v-if="popupOpen" @close-popup="closePopup" />
    <faq-footer @open-popup="openPopup" />
  </article>
</template>
<script>
import { mapGetters } from "vuex";
import FaqFooter from "./FaqFooter.vue";
import PopupItem from "../components/PopupItem.vue";

export default {
  name: "FaqContent",
  components: { FaqFooter, PopupItem },
  mixins: [],
  props: {},
  data() {
    return {
      popupOpen: false,
    };
  },
  computed: {
    ...mapGetters({
      locale: "app/getLocale",
      FaqData: "app/getFaqData",
    }),
    currentChapterIndex() {
      return this.FaqData.findIndex(
        (chapter) => chapter.slug === this.$route.params.chapter
      );
    },

    currentContentIndex() {
      if (this.currentChapterIndex === -1) return -1;
      return this.FaqData[this.currentChapterIndex].content.findIndex(
        (content) => content.slug === this.$route.params.slug
      );
    },
    prevContent() {
      const currentChapterIndex = this.currentChapterIndex;
      const currentContentIndex = this.currentContentIndex;

      // Если текущий вопрос - первый в текущем главе
      if (currentContentIndex === 0) {
        // Если текущая глава - первая, возвращаем последний вопрос последней главы
        if (currentChapterIndex === 0) {
          const lastChapterIndex = this.FaqData.length - 1;
          const lastContentIndex =
            this.FaqData[lastChapterIndex].content.length - 1;
          return this.formatContentData(
            this.FaqData[lastChapterIndex],
            this.FaqData[lastChapterIndex].content[lastContentIndex]
          );
        } else {
          // Возвращаем последний вопрос предыдущей главы
          const prevChapterIndex = currentChapterIndex - 1;
          const lastContentIndex =
            this.FaqData[prevChapterIndex].content.length - 1;
          return this.formatContentData(
            this.FaqData[prevChapterIndex],
            this.FaqData[prevChapterIndex].content[lastContentIndex]
          );
        }
      } else {
        // Возвращаем предыдущий вопрос в текущей главе
        return this.formatContentData(
          this.FaqData[currentChapterIndex],
          this.FaqData[currentChapterIndex].content[currentContentIndex - 1]
        );
      }
    },
    nextContent() {
      const currentChapterIndex = this.currentChapterIndex;
      const currentContentIndex = this.currentContentIndex;

      // Если текущий вопрос - последний в текущем главе
      if (
        currentContentIndex ===
        this.FaqData[currentChapterIndex].content.length - 1
      ) {
        // Если текущая глава - последняя, возвращаем первый вопрос первой главы
        if (currentChapterIndex === this.FaqData.length - 1) {
          return this.formatContentData(
            this.FaqData[0],
            this.FaqData[0].content[0]
          );
        } else {
          // Возвращаем первый вопрос следующей главы
          const nextChapterIndex = currentChapterIndex + 1;
          return this.formatContentData(
            this.FaqData[nextChapterIndex],
            this.FaqData[nextChapterIndex].content[0]
          );
        }
      } else {
        // Возвращаем следующий вопрос в текущей главе
        return this.formatContentData(
          this.FaqData[currentChapterIndex],
          this.FaqData[currentChapterIndex].content[currentContentIndex + 1] // Исправление здесь
        );
      }
    },

    contentData() {
      let chapter = this.FaqData.find(
        (chapter) => chapter.slug === this.$route.params.chapter
      );
      let question = chapter.content.find(
        (q) => q.slug === this.$route.params.slug
      );
      let current = {
        chapter,
        question,
      };

      let homeLink = `/${this.locale}/${this.FaqData[0].slug}/${this.FaqData[0].content[0].slug}`;
      let chapterLink = `/${this.locale}/${current.chapter.slug}/`;
      let chapterTitle = current.chapter["title_" + this.locale];
      let currentLink = this.$route.path;
      let currentQ = current.question["title_" + this.locale];
      let htmlContent = this.processHtmlContent(
        current.question["text_" + this.locale]
      );

      return {
        homeLink,
        chapterLink,
        chapterTitle,
        currentLink,
        currentQ,
        htmlContent,

        nextRoute: this.nextContent.link,
        nextLabel: this.nextContent.label,
        prevRoute: this.prevContent.link,
        prevLabel: this.prevContent.label,
      };
    },
  },

  methods: {
    closePopup() {
      this.popupOpen = false;
    },
    openPopup() {
      this.popupOpen = true;
    },
    formatContentData(chapter, content) {
      const label = content["title_" + this.locale];
      const link = `/${this.locale}/${chapter.slug}/${content.slug}`;
      return {
        link,
        label,
      };
    },
    processHtmlContent(html) {
      const parser = new DOMParser();
      const doc = parser.parseFromString(html, "text/html");
      const blockquotes = doc.querySelectorAll("blockquote");

      blockquotes.forEach((blockquote) => {
        blockquote.classList.add("general__block"); // Добавляем класс к blockquote
        const span = document.createElement("span"); // Создаем элемент span
        span.className = "icon-info general__icon-info"; // Добавляем классы к span
        blockquote.insertBefore(span, blockquote.firstChild); // Вставляем span в начало blockquote
      });

      return doc.body.innerHTML; // Возвращаем измененный HTML как строку
    },
  },
};
</script>
<style lang="scss">
.html-wrapper {
  overflow: hidden;
  max-width: 100%;
}
.html-wrapper ul {
  padding-left: 20px;
  margin-bottom: 10px;
}
.html-wrapper ul li::marker {
  color: gray; // Только для маркера
}

.html-wrapper > p {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}
</style>
