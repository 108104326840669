export default {
  btn_in: "Accedi",
  "footer-item-1": "Home",
  "footer-item-2": "Chi siamo",
  "footer-item-3": "Primi passi",
  "footer-item-4": "Investimenti",
  "footer-item-5": "Programma di Referral",
  "footer-item-6": "Statistiche",
  "footer-item-7": "Stato del Sistema",
  "footer-item-8": "Sezioni",
  "footer-item-8-1": "Risorse",
  "footer-item-9": "Azienda",
  "footer-item-9-1": "Politica AML",
  "footer-item-9-2": "Termini di utilizzo del sito web",
  "footer-item-9-3": "Informativa sulla privacy",
  "footer-item-10": "Contattaci",
  "footer-item-11": "FAQ",
  "footer-item-12": "Documentazione",

  "footer-form-1": "Nome",
  "footer-form-2": "Telefono",
  "footer-form-3": "Email",
  "footer-form-4": "Il tuo messaggio",
  "footer-btn": "Invia",
  "pop-up-title": "Il tuo messaggio è stato inviato con successo",
};
