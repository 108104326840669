export default {
  btn_in: "Einloggen",
  "footer-item-1": "Startseite",
  "footer-item-2": "Über uns",
  "footer-item-3": "Erste Schritte",
  "footer-item-4": "Investieren",
  "footer-item-5": "Partnerprogramm",
  "footer-item-6": "Statistiken",
  "footer-item-7": "Systemstatus",
  "footer-item-8": "Abschnitte",
  "footer-item-8-1": "Ressourcen",
  "footer-item-9": "Unternehmen",
  "footer-item-9-1": "AML-Richtlinie",
  "footer-item-9-2": "Nutzungsbedingungen der Website",
  "footer-item-9-3": "Datenschutzrichtlinie",
  "footer-item-10": "Kontaktieren Sie uns",
  "footer-item-11": "FAQ",
  "footer-item-12": "Dokumentation",

  "footer-form-1": "Name",
  "footer-form-2": "Telefon",
  "footer-form-3": "E-Mail",
  "footer-form-4": "Ihre Nachricht",
  "footer-btn": "Senden",
  "pop-up-title": "Deine Nachricht wurde erfolgreich gesendet",
};
