import Axios from "axios";
import store from "@/store";

const options = {
  getLocale: (state) => state.locale,
  baseURL: `${process.env.VUE_APP_API}api/`,
  headers: {
    Accept: "application/json",
    "Content-Type": "multipart/form-data",
  },
};
const httpClient = Axios.create(options);
//interceptors.request
httpClient.interceptors.request.use((config) => {
  if (store.getters["app/getLocale"]) {
    config.headers["Accept-Language"] = store.getters["app/getLocale"];
  }
  return config;
});

const ApiClient = {
  get(url, conf = {}) {
    return httpClient.get(url, conf);
  },

  put(url, data = {}, conf = {}) {
    return httpClient.put(url, data, conf);
  },

  delete(url, conf = {}) {
    return httpClient.delete(url, conf);
  },

  post(url, data = {}, conf = {}) {
    return httpClient.post(url, data, conf);
  },

  patch(url, data = {}, conf = {}) {
    return httpClient.patch(url, data, conf);
  },
};

export default ApiClient;
