export default {
  btn_in: "Giriş yap",
  "footer-item-1": "Ana Sayfa",
  "footer-item-2": "Hakkımızda",
  "footer-item-3": "İlk Adımlar",
  "footer-item-4": "Yatırım",
  "footer-item-5": "Yönlendirme programı",
  "footer-item-6": "İstatistikler",
  "footer-item-7": "Sistem Durumu",
  "footer-item-8": "Bölümler",
  "footer-item-8-1": "Kaynaklar",
  "footer-item-9": "Şirket",
  "footer-item-9-1": "AML Politikası",
  "footer-item-9-2": "Web Sitesi Kullanım Koşulları",
  "footer-item-9-3": "Gizlilik Politikası",
  "footer-item-10": "Bize Ulaşın",
  "footer-item-11": "SSS",
  "footer-item-12": "Belgeler",

  "footer-form-1": "Ad",
  "footer-form-2": "Telefon",
  "footer-form-3": "E-posta",
  "footer-form-4": "Mesajınız",
  "footer-btn": "Gönder",
  "pop-up-title": "Mesajınız başarıyla gönderildi",
};
