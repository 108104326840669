export default {
  btn_in: "Conectarse",
  "footer-item-1": "Inicio",
  "footer-item-2": "Sobre Nosotros",
  "footer-item-3": "Primeros pasos",
  "footer-item-4": "Inversión",
  "footer-item-5": "Programa de referidos",
  "footer-item-6": "Estadísticas",
  "footer-item-7": "Estado del sistema",
  "footer-item-8": "Secciones",
  "footer-item-8-1": "Recursos",
  "footer-item-9": "Empresa",
  "footer-item-9-1": "Política AML",
  "footer-item-9-2": "Términos de uso del sitio web",
  "footer-item-9-3": "Política de privacidad",
  "footer-item-10": "Contáctanos",
  "footer-item-11": "Preguntas Frecuentes",
  "footer-item-12": "Documentación",

  "footer-form-1": "Nombre",
  "footer-form-2": "Teléfono",
  "footer-form-3": "Correo electrónico",
  "footer-form-4": "Tu mensaje",
  "footer-btn": "Enviar",
  "pop-up-title": "Tu mensaje ha sido enviado exitosamente",
};
