export default {
  btn_in: "Inloggen",
  "footer-item-1": "Home",
  "footer-item-2": "Over ons",
  "footer-item-3": "Eerste stappen",
  "footer-item-4": "Investeren",
  "footer-item-5": "Verwijzingsprogramma",
  "footer-item-6": "Statistieken",
  "footer-item-7": "Systeemstatus",
  "footer-item-8": "Secties",
  "footer-item-8-1": "Bronnen",
  "footer-item-9": "Bedrijf",
  "footer-item-9-1": "AML-beleid",
  "footer-item-9-2": "Gebruiksvoorwaarden van de website",
  "footer-item-9-3": "Privacybeleid",
  "footer-item-10": "Neem contact met ons op",
  "footer-item-11": "FAQ",
  "footer-item-12": "Documentatie",

  "footer-form-1": "Naam",
  "footer-form-2": "Telefoon",
  "footer-form-3": "E-mail",
  "footer-form-4": "Uw bericht",
  "footer-btn": "Verzenden",
  "pop-up-title": "Uw bericht is succesvol verzonden",
};
