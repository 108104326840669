import Vue from "vue";
import VueRouter from "vue-router";
import LoadingComponent from "@/components/LoadingComponent.vue";
import FaqContent from "@/components/FaqContent.vue";
import store from "@/store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/:locale/:chapter/:slug",
    component: FaqContent,
  },
  {
    path: "/loading",
    component: LoadingComponent,
  },
];
const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

router.beforeEach((to, from, next) => {
  const defaultSlug = store.getters["app/getDefaultSlug"];
  const defaultChapter = store.getters["app/getDefaultChapter"];
  const currentLocale = store.getters["app/getLocale"];
  const localeList = store.getters["app/getLocalesList"];
  const faqData = store.getters["app/getFaqData"];
  const availableChapters = store.getters["app/getAvailableChapters"];
  // const availableSlugs = store.getters["app/getAvailableSlugs"];
  const defaultRout = `/${currentLocale}/${defaultChapter}/${defaultSlug}`;

  if (store.getters["app/isLoadingData"]) {
    if (to.path !== "/loading") {
      store.commit("app/SET_INTENDED_ROUTE", to);
      next("/loading");
    } else {
      next();
    }

    const unwatch = store.watch(
      (state) => state.app.isLoadingData,
      (isLoading) => {
        if (!isLoading) {
          unwatch();
          const intendedRoute = store.getters["app/getIntendedRoute"] || {
            path: `/${currentLocale}/${defaultChapter}/${defaultSlug}`,
          };
          next(intendedRoute);
        }
      }
    );
  } else {
    const pathSegments = to.path.split("/").filter(Boolean);
    let localeSegment = pathSegments[0];
    let chapterSegment = pathSegments[1];
    let slugSegment = pathSegments[2];

    // Проверка наличия только baseUrl
    if (pathSegments.length === 0) {
      next(defaultRout);
      return;
    }
    // Проверка локали
    if (!localeList.includes(localeSegment)) {
      next(defaultRout);
      return;
    }

    if (localeSegment !== currentLocale) {
      let correctLocale = currentLocale;
      if (localeList.includes(localeSegment)) {
        store.dispatch("app/setLocale", localeSegment);
        correctLocale = localeSegment;
      }
      const correctSlug =
        chapterSegment && slugSegment
          ? `${chapterSegment}/${slugSegment}`
          : defaultRout;

      next(`/${correctLocale}/${correctSlug}`);
      return;
    }

    // Проверяем наличие сегмента чаптера и слага
    if (pathSegments.length >= 2) {
      // Проверяем валидность чаптера
      if (availableChapters.includes(chapterSegment)) {
        // Чаптер валиден, теперь проверяем слаг
        const chapterData = faqData.find((faq) => faq.slug === chapterSegment);

        // Если слаг отсутствует или невалиден, используем первый слаг из этого чаптера
        if (
          !slugSegment ||
          !chapterData.content.some((content) => content.slug === slugSegment)
        ) {
          slugSegment = chapterData.content[0].slug;
          // Формируем путь для возможного перенаправления
          const newPath = `/${localeSegment}/${chapterSegment}/${slugSegment}`;

          // Если текущий путь не совпадает с потенциальным новым путем, перенаправляем
          if (to.path !== newPath) {
            next(newPath);
          } else {
            // Иначе, просто продолжаем навигацию
            next();
          }
        } else {
          // Если и чаптер, и слаг валидны, просто продолжаем навигацию
          next();
        }
      } else {
        // Если чаптер невалиден, перенаправляем на маршрут с дефолтными чаптером и слагом
        next(`/${localeSegment}/${defaultChapter}/${defaultSlug}`);
      }
      return;
    }

    // Продолжение навигации, если не требуется перенаправление
    next();
  }
});

export default router;
