import AppServices from "../../services/app.services";

let state = {
  locale: "",
  localesList: ["ru", "en", "it", "es", "de", "nl", "tr", "fa"],
  FaqData: [],
  theme: "dark",
  intendedRoute: "",
  isLoadingData: true,
  availableChapters: [],
  availableSlugs: [],
};

const mutations = {
  SET_LOCALE(state, locale) {
    const topLevelDomain = ".coinfuze.com";
    document.cookie = `locale=${encodeURIComponent(
      locale
    )};domain=${topLevelDomain};path=/`;
    state.locale = locale;
  },
  SET_FAQ_DATA(state, data) {
    state.FaqData = data.map((item) => ({
      ...item,
      opened: false,
    }));
    state.availableChapters = data.map((faq) => faq.slug);
    // state.availableSlugs = data.flatMap((faq) =>
    //   faq.content.map((content) => content.slug)
    // );
  },
  TOGGLE_FAQ_ITEM(state, { id, value }) {
    const item = state.FaqData.find((faq) => faq.id === id);
    if (item) {
      item.opened = value !== undefined ? value : !item.opened;
    }
  },
  SET_THEME(state, theme) {
    state.theme = theme;
  },
  SET_INTENDED_ROUTE(state, route) {
    state.intendedRoute = route;
  },
  SET_LOADING(state, data) {
    state.isLoadingData = data;
  },
};

const actions = {
  setLocale({ commit }, locale) {
    commit("SET_LOCALE", locale);
  },
  getFaqData({ commit }) {
    return new Promise((resolve, reject) => {
      AppServices.get("getFaqData")
        .then((response) => {
          commit("SET_FAQ_DATA", response.data.results);
          return resolve(response);
        })
        .catch((error) => {
          reject(error);
        })
        .finally(() => {
          commit("SET_LOADING", false);
        });
    });
  },
};

const getters = {
  getLocale: (state) => state.locale,
  getLocalesList: (state) => state.localesList,
  getTheme: (state) => state.theme,

  getFaqData: (state) => state.FaqData,
  getDefaultSlug: (state) => state.FaqData[0]?.content[0].slug,
  getDefaultChapter: (state) => state.FaqData[0]?.slug,
  getIntendedRoute: (state) => state.intendedRoute,
  getAvailableChapters: (state) => state.availableChapters,
  // getAvailableSlugs: (state) => state.availableSlugs,

  isLoadingData: (state) => state.isLoadingData,
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
